import { Box, Modal } from "@mui/material";
import React from "react";
import { style } from "../constants";
import "../assests/styles/ErrorModal.css";
import ErrorSVG from "../assests/svg/error";
const ErrorModal = ({ open, setOpen, title, message, handleClose }) => {
  return (
    <Modal
      className="error-modal"
      open={open}
      aria-labelledby="modal-modal-title"
      onClose={() => {
        setOpen(false);
        if (typeof handleClose === "function") {
          handleClose();
        }
      }}
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="error-modal">
          <div className="error-svg">
            <ErrorSVG />
          </div>
          <div className="error-header">{title}</div>
          <div className="error-body">{message}</div>
        </div>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
