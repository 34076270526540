import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateDistance,
  predictPrice,
  predictPriceUsingId,
} from "../api/index";
import {
  removeResult,
  setPricedData,
  setPricedDataFromId,
} from "../redux/actions/actions";
import Card from "../assests/images/card.png";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import "../assests/styles/RecommendPrice.css";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import { Modal } from "@mui/material";
import { GridLoader } from "react-spinners";
import PriceAcceptedButton from "./PriceAcceptedButton";
const RecommendPrice = ({ handleClose }) => {
  const app = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [predictedPrice, setPredictedPrice] = React.useState([]);
  const [recommendationIds, setRecommendationIds] = React.useState([]);
  const API_TIMEOUT_TIME = 20000;
  const KM = 1000;
  useEffect(() => {
    setLoading(true);
    const g = window.google;
    if (g) {
      let predictedPriceArray = [];
      let recommendationIdArray = [];

      // since now we arent supposed to map, we will have to sum all the data and predict the price

      const distanceServie = new g.maps.DistanceMatrixService();
      console.log("app?.final_data", app?.final_data);
      console.log(
        "app?.final_data[0]?.origin?.postalCode",
        app?.final_data[0]?.totalValues?.origin?.postalCode
      );

      //
      const ldmSum = app?.final_data
        .map((obj) => obj.totalValues.ldm)
        .reduce((acc, curr) => acc + curr, 0);

      const weightSum = app?.final_data
        .map((obj) => parseFloat(obj.totalValues.weight))
        .reduce((acc, curr) => acc + curr, 0);

      const predictPriceMethod = async () => {
        const origins = [
          `${app?.final_data[0]?.totalValues?.origin?.postalCode}, ${app?.final_data[0]?.totalValues?.origin?.country}`,
        ];
        const destinations = [
          `${app?.final_data[0]?.totalValues?.destination?.postalCode}, ${app?.final_data[0]?.totalValues?.destination?.country}`,
        ];
        await distanceServie.getDistanceMatrix(
          {
            origins,
            destinations,
            travelMode: "DRIVING",
            region: "eu",
          },
          (response, status) => {
            if (status === "OK" && response.rows[0].elements[0].distance) {
              // works
              //   alert("PRice predicted"); // works
              console.log("1", response); // works
              //   alert("PRice predicted");
              predictPrice(
                {
                  zone: "eu",
                  loading_type: "ftl",
                  distance: response.rows[0].elements[0].distance?.value / KM,
                  weight: weightSum,
                  ldm: ldmSum,
                  pickup_location_type:
                    app?.final_data[0]?.totalValues?.origin?.location_type,
                  pickup_country:
                    app?.final_data[0]?.totalValues?.origin?.country,
                  pickup_postal_code:
                    app?.final_data[0]?.totalValues?.origin?.postalCode,
                  pickup_address:
                    app?.final_data[0]?.totalValues?.origin?.address,
                  delivery_location_type:
                    app?.final_data[0]?.totalValues?.destination?.location_type,
                  delivery_country:
                    app?.final_data[0]?.totalValues?.destination?.country,
                  delivery_postal_code:
                    app?.final_data[0]?.totalValues?.destination.postalCode,
                  delivery_address:
                    app?.final_data[0]?.totalValues?.destination.address,
                },
                app?.currentUser?.token
              )
                .then((response) => {
                  setTimeout(() => {
                    predictPriceUsingId(
                      response?.data?.price_recommendation_id,
                      app?.currentUser?.token
                    )
                      .then((res) => {
                        if (
                          res?.data?.price_recommendation_result
                            ?.recommended_price
                        ) {
                          dispatch(
                            setPricedDataFromId(
                              res?.data?.price_recommendation_result
                            )
                          );
                          setLoading(false);
                          recommendationIdArray.push(
                            response?.data?.price_recommendation_id
                          );
                        } else {
                          setLoading(false);
                        }
                      })
                      .catch((error) => {
                        if (error?.response?.data?.detail?.length > 0) {
                          setShowError(true);
                          setErrorMessage(error?.response?.data?.detail);
                          setLoading(false);
                        }
                      });
                  }, [API_TIMEOUT_TIME]);
                })
                .catch((err) => {
                  setShowError(true);
                  setErrorMessage(err?.response?.data?.detail);
                  setLoading(false);
                });
            } else {
              setShowError(true);
              setErrorMessage("Distance was not calculated");
              setLoading(false);
            }
          }
        );
      };

      predictPriceMethod();
      setPredictedPrice(predictedPriceArray);
      setRecommendationIds(recommendationIdArray);

      //   // const totalValues = app?.final_data.map(async (item, index) => {
      //   //     const distanceServie = new g.maps.DistanceMatrixService();
      //   //     await distanceServie.getDistanceMatrix(
      //   //         {
      //   //             origins: [`75003`],
      //   //             destinations: [`60329`],
      //   //             travelMode: 'DRIVING',
      //   //         },
      //           (response, status) => {
      //               if (status === 'OK' && response.rows[0].elements[0].distance) {
      //                   //response.rows[0].elements[0].distance
      //                   predictPrice({
      //                       "zone": "eu",
      //                       "loading_type": "ftl",
      //                       "distance": (response.rows[0].elements[0].distance?.value)/100,
      //                       "weight": item?.totalValues?.weight,
      //                       "ldm": item?.totalValues?.ldm,
      //                       "pickup_location_type": item?.totalValues?.origin?.location_type,
      //                       "pickup_country": item?.totalValues?.origin?.country,
      //                       "pickup_postal_code": item?.totalValues?.origin?.postalCode,
      //                       "pickup_address": item?.totalValues?.origin?.address,
      //                       "delivery_location_type": item?.totalValues?.destination?.location_type,
      //                       "delivery_country": item?.totalValues?.destination?.country,
      //                       "delivery_postal_code": item?.totalValues?.destination.postalCode,
      //                       "delivery_address": item?.totalValues?.destination.address,
      //                   }, app?.currentUser?.token).then((response) => {
      //                       setTimeout(() => {

      //                           predictPriceUsingId(response?.data?.price_recommendation_id, app?.currentUser?.token).then((res) => {
      //                               if (res?.data?.price_recommendation_result?.recommended_price) {
      //                                   dispatch(setPricedDataFromId(res?.data?.price_recommendation_result))
      //                                   setLoading(false);
      //                                   // issue here
      //                                   recommendationIdArray.push(response?.data?.price_recommendation_id);
      //                               }
      //                               else {
      //                                   // setShowError(true);
      //                                   // setErrorMessage("Price recommendation failed");
      //                                   setLoading(false);
      //                               }

      //                           }).catch((error) => {
      //                               setShowError(true);
      //                               setErrorMessage(error?.response?.data?.detail);
      //                               setLoading(false);

      //                           })
      //                       }, [API_TIMEOUT_TIME])

      //                   }).catch((err) => {
      //                       setShowError(true);
      //                       setErrorMessage(err?.response?.data?.detail);
      //                       setLoading(false);

      //                   })
      //               }
      //               else {
      //                   setShowError(true);
      //                   setErrorMessage('Distance was not calculated');
      //                   setLoading(false);
      //               }
      //           },
      //       );

      //   })
      // setPredictedPrice(predictedPriceArray);
      // setRecommendationIds(recommendationIdArray)
    }
  }, [app?.currentUser?.token, app?.final_data, dispatch]);

  const [count, setCount] = React.useState(0);

  const errorHandler = (e) => {
    setShowError(e);
    if (errorMessage?.includes("subscription not found for the user")) {
      dispatch(removeResult());
      navigate("/subscriptions");
    }
  };

  const getRecommendedPriceUpperBand = (e) => {
    if (e !== null && e !== undefined) {
      return Object?.keys(e?.probs)[0].split(",")[0];
    } else {
      return "";
    }
  };
  const getRecommendedPriceLowerBand = (e) => {
    if (e !== null && e !== undefined) {
      return Object?.keys(e?.probs)[0].split(",")[1];
    } else {
      return "";
    }
  };

  const priceAcceptedButton = () => {
    // send data to the backend
  };

  return (
    <>
      <ErrorModal
        open={showError}
        setOpen={errorHandler}
        title={"Price recommendation failed"}
        message={errorMessage}
        handleClose={handleClose}
      />
      <Modal
        className="login-modal"
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <GridLoader
          cssOverride={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate( -50%, -50%)",
          }}
          loading={loading}
        />
      </Modal>
      {!loading && !showError && (
        <div className="recommendedPrice">
          <div className="recommendedPrice-left">
            <div className="recommendedPrice-left-image">
              <img alt="card" src={Card} />
            </div>
            <div className="recommendedPrice-left-container">
              {app?.priced_data_id?.length > 0 && (
                <div className="recommendedPrice-left-container-text">
                  Recommended price : €
                  {app?.priced_data_id[count]?.recommended_price}
                </div>
              )}
              {app?.priced_data_id?.length > 0 && (
                <div className="recommendedPrice-left-container-text">
                  Recommended range : €
                  {getRecommendedPriceUpperBand(
                    app?.priced_data_id[count]?.ftl_price
                  )}{" "}
                  - €{app?.priced_data_id[count]?.recommended_price} - €
                  {getRecommendedPriceLowerBand(
                    app?.priced_data_id[count]?.ftl_price
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="recommendedPrice-right">
            <div className="recommendedPrice-right-top">
              {app?.priced_data_id?.length > 0 && (
                <div className="recommendedPrice-right-top-text">
                  Full Truck Load (FTL): {app?.priced_data_id[count]?.num_ftl}
                </div>
              )}
              {app?.priced_data_id?.length > 0 && (
                <div className="recommendedPrice-right-top-text">
                  Less Than Truck Load (LTL):{" "}
                  {app?.priced_data_id[count]?.num_ltl}
                </div>
              )}
            </div>
            <div className="recommendedPrice-right-bottom">
              {[...Array(count).keys()]?.map((item, index) => (
                <>
                  {item === index + 1 && (
                    <PriceAcceptedButton
                      token={app?.currentUser?.token}
                      recommendationIds={recommendationIds}
                      count={count}
                    />
                  )}
                </>
              ))}
              <PriceAcceptedButton
                token={app?.currentUser?.token}
                recommendationIds={recommendationIds}
                count={count}
              />
            </div>
          </div>
        </div>
      )}
      {!loading && !showError && (
        <div className="price-wrapper">
          <div
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            className="recommendedPrice-right-bottom-button-finish"
          >
            Finish
          </div>
        </div>
      )}
      {/* Disabling pagination for the time being */}
      {/* {!loading && (
        <div className="pagination">
          <Stack spacing={2}>
            <Pagination
              onChange={(e, page) => {
                setCount(page - 1);
              }}
              count={app?.priced_data_id?.length}
              color="primary"
            />
          </Stack>
        </div>
      )} */}
    </>
  );
};

export default RecommendPrice;
