import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';
import { useDispatch } from 'react-redux';
import { GridLoader } from 'react-spinners';
import { currentPlan, login, subscription } from '../../api';
import { useNavigate } from 'react-router-dom';

import '../../assests/styles/Login.css';
import ErrorModal from '../../components/ErrorModal';
import SuccessModal from '../../components/SuccessModal';
import { loginFailedTitle, loginSuccessMessage, loginSuccessTitle, style } from '../../constants/index';
import { removeResult, saveToken, setCurrentUser } from '../../redux/actions/actions';
import { EmailService } from '../../services/emailValidation';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const Login = ({ open, handleClose }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false);
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const navigate = useNavigate();

    const [loginForm, setLoginForm] = React.useState({
        email: '',
        password: '',
    })
    const [errorMessage, setErrorMessage] = React.useState('')
    const [showErrorModal, setShowErrorModal] = React.useState(false);

    const LoginComplete = (token) => {
        handleClose()
        dispatch(setCurrentUser({
            firstName: loginForm.email.split('@')[0],
            lastName: loginForm.email.split('@')[0],
            email: loginForm.email,
            token
        }));
        setLoading(false);
        setShowSuccessModal(true)
        setTimeout(()=>{
            setShowSuccessModal(false)
        },[2000])
    }

    const isLoginValid = () => {
        const isFormEmpty = !Object.values(loginForm).some(x => (x !== null && x !== ''))
        const isEmailValid = EmailService.isEmailValid(loginForm.email)
        console.log(isFormEmpty, isEmailValid,loginForm.email);
        if(isFormEmpty){
            return {
                message: 'No fields entered',
                status: false
            }
        }
        if(loginForm.email === ''){
            return {
                message: 'Email cannot be an empty string',
                status: false
            }
        }
        if(!isEmailValid){
            return {
                message: 'Email is not valid',
                status: false
            }
        }
        if(loginForm.password === ''){
            return {
                message: 'Password must not be an empty string',
                status: false
            }
        }
        return {
            message: '',
            status: true
        }
     
    }

    const submitHandler = () => {
        console.log(loginForm)
        dispatch(removeResult())
        const emailValidation = isLoginValid();
        if(emailValidation.status){
            setLoading(true);
            login(loginForm.email, loginForm.password).then((res) => {
                currentPlan(res.data.access_token).then((response)=>{
                    console.log('response from email', res);    
                    LoginComplete(res.data.access_token);
                }).catch((error)=>{
                    if(error.response.data.detail.includes('subscription not found')){
                        subscription(res.data.access_token).then((response)=>{
                            LoginComplete(res.data.access_token);
                        }).catch((err)=>{
                            setShowErrorModal(true)
                            setErrorMessage(error?.response?.data?.detail)
                            setLoading(false);
                            setLoginForm({})
                            
                        })
                      }
                      else {
                        setShowErrorModal(true);
                        setErrorMessage(error?.response?.data?.detail)
                        setLoading(false);
                        setLoginForm({})
                      }
                   
                })
            }).catch((err) => {
                setShowErrorModal(true);
                setErrorMessage(err?.response?.data?.detail)
                setLoading(false);
                setLoginForm({})
            })
        }
        else {
            handleClose()
            setShowErrorModal(true);
            setErrorMessage(emailValidation.message)
            setLoading(false);
            setLoginForm({})
        }
    }

    const forgotPasswordHandler = () => {
        handleClose()
        navigate('/forgot_password')
    }
    return (
        <div className="login">
            <SuccessModal open={showSuccessModal} setOpen={setShowSuccessModal} title={loginSuccessTitle} message={loginSuccessMessage + loginForm?.email?.split('@')[0]} />
            <ErrorModal open={showErrorModal} setOpen={setShowErrorModal} title={loginFailedTitle} message={errorMessage} />
            <Modal
                className="login-modal"
                open={loading}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <GridLoader cssOverride={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate( -50%, -50%)' }} loading={loading} />
            </Modal>
            <Modal
                className="login-modal"
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
   

                <Box sx={style}>
                    <div className="close-icon-wrapper">
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    </div>


                    <div className="login-header">Login</div>
                    <div className="login-container">
                        <div className="login-container-items"><input name="emailForLogin" autoComplete="off" onChange={(e) => { setLoginForm({ ...loginForm, email: e.target.value }) }} placeholder="Enter your email:" type="text" /></div>
                        <div className="login-container-items"><input name="passwordForLogin" autoComplete="new-password" onChange={(e) => { setLoginForm({ ...loginForm, password: e.target.value }) }} placeholder="Enter your password:" type="password" /></div>
                        <div onClick={submitHandler} className="login-container-button"><p>Sign In</p></div>
                        <div onClick={forgotPasswordHandler} className="login-container-forgot-password">Forgot Password?</div>
                    </div>

                </Box>

            </Modal>

        </div>
    )
}

export default Login