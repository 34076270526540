import React from 'react'
import { priceAcceptedApi } from '../api';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const PriceAcceptedButton = ({count, token, recommendationIds}) => {
    const [priceAccepted, setPriceAccepted] = React.useState(false);
    let acceptedPriceCount = []
    const [selectedRecommendationId, setSelectedRecommendationId] = React.useState('');
    React.useEffect(()=>{
      
      setPriceAccepted(false);
      setSelectedRecommendationId(recommendationIds[count])
    }, [count, recommendationIds])
    const [completedR, setCompletedR] = React.useState('');
    let completedRArray = []
    const priceAcceptedButton = () => {
      if(!completedR.includes(selectedRecommendationId)){
        setPriceAccepted(!priceAccepted);
        priceAcceptedApi(recommendationIds[count], token).then((res)=>{
          setPriceAccepted(true);
          acceptedPriceCount.push(count); 
          completedRArray.push(recommendationIds[count])
          setSelectedRecommendationId(recommendationIds[count]);
          setCompletedR([...completedR, recommendationIds[count]]);
      })
      }
    }
  return (
    <div style={{ cursor: 'pointer' }} onClick={priceAcceptedButton} className="recommendedPrice-right-bottom-button">Is this price acceptable?{completedR.includes(selectedRecommendationId) &&  <span style={{display:'flex', alignItems:'center', justifyContent:'center'}}><CheckCircleOutlineIcon style={{marginLeft:'6px'}} /></span>} </div> 
  )
} 

export default PriceAcceptedButton