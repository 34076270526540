import React from 'react'
import { sendEmail } from '../api'
import '../assests/styles/ContactUs.css'
import ErrorModal from '../components/ErrorModal'
import SuccessModal from '../components/SuccessModal'
import Navbar from './Navbar'
const ContactUs = ({ authentication }) => {
    const [emailSent, setEmailSent] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);
    const [contactUsForm, setContactUsForm] = React.useState({
        name: '',
        email: '',
        role: '',
        message: ''
    })

    const submitHandler = (e) => {
        e.preventDefault()
        if(contactUsForm.name !== '' && contactUsForm.email !== '' && contactUsForm.role !== '' && contactUsForm.message !== ''){
            sendEmail({
                // replacing customer with "costUmer" as backend is accepting only for custUmer
                costumer_name: contactUsForm.name,
                costumer_email: contactUsForm.email,
                costumer_role: contactUsForm.role,
                costumer_message: contactUsForm.message
            }).then((res)=>{
                setEmailSent(true);
                setContactUsForm({
                    name: '',
                    email: '',
                    role: '',
                    message: ''
                })
            }).catch((err)=>{
                setError(true); 
                // setErrorMessage(err?.response?.data?.detail);
                console.log(err?.response?.data?.detail[0]?.msg);
            })
        }
    }
    return (

        <div>
            <SuccessModal open={emailSent} setOpen={setEmailSent} title={'Message Sent'} message={'The message has been sent successfully'} />
            <ErrorModal open={error} setOpen={setError} title={'Message sending failed'} message={errorMessage} />
            <Navbar authentication={authentication} />
            <div className="contact-us-page">
                <div className="contact-us-container">
                    <div className="contact-us-container-header">CONTACT US</div>
                    <div className="contact-us-container-email">Email : sales@kaizentech.io</div>
                    <div className="contact-us-container-body">
                        <div className="contact-inputfields-wrapper">
                            <div style={{ marginLeft: '10px' }} className="contact-us-container-item-field">
                                <input value={contactUsForm.name} onChange={(e)=>{
                                    setContactUsForm({...contactUsForm, name: e.target.value})
                                }}  style={{ marginLeft: '10px' }} placeholder="Enter your name" type="text" />
                            </div>
                            <div style={{ marginLeft: '10px' }} className="contact-us-container-item-field">
                                <input value={contactUsForm.email} onChange={(e)=>{
                                    setContactUsForm({...contactUsForm, email: e.target.value})
                                }} style={{ marginLeft: '10px' }} placeholder="Enter your email address" type="text" />
                            </div>
                            <div style={{ marginLeft: '10px' }} className="contact-us-container-item-field">
                                <input value={contactUsForm.role} onChange={(e)=>{
                                    setContactUsForm({...contactUsForm, role: e.target.value})
                                }} style={{ marginLeft: '10px' }} placeholder="Enter your role" type="text" />
                            </div>
                            <div style={{ marginLeft: '10px', height: '10vh' }} className="contact-us-container-item-field">
                                <textarea value={contactUsForm.message}  onChange={(e)=>{
                                    setContactUsForm({...contactUsForm, message: e.target.value})
                                }} style={{ marginLeft: '10px' }} placeholder="Enter your message" type="text" />
                            </div>
                        </div>

                        <div onClick={submitHandler} className="contact-us-header-item item-3-contact">
                            <p>Contact</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs