import React from "react";
import "../assests/styles/OriginTab.css";
import { ZipCodeService } from "../services/zipCodeService";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import TextField from "@mui/material/TextField";

const OriginTab = ({ selectedTab, setSelectedTab, storeOriginFormValues }) => {
  const [showError, setShowError] = React.useState(false);
  const placeRef = React.useRef();
  const [address, setAddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [location, setLocation] = "";

  const _googlePlaceOption = {
    componentRestrictions: { country: ["de", "fr", "pl", "nl"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  };
  const [selectedLocationType, setSelectedLocationType] = React.useState(-1);
  const [originFormData, setOriginFormData] = React.useState({
    address: "",
    country: "",
    location_type: "",
    postalCode: "",
  });
  const callers = {
    location: setLocation,
  };
  const clickHandler = () => {
    if (postcodeValidator(postalCode, "INTL")) {
      if (
        address !== "" &&
        postalCode !== "" &&
        country !== "" &&
        selectedLocationType !== -1
      ) {
        setSelectedTab(1);
        storeOriginFormValues({
          address: address,
          country: country,
          location_type: selectedLocationType,
          postalCode: postalCode,
        });
      }
    } else {
      setShowError(true);
    }

    // console.log(ZipCodeService.extractZipCode(originFormData.address));
  };

  const _getPlace = (autoComplete) => {
    const { address_components } = autoComplete.getPlace();
    console.log("a", address_components);
    let streetNumber = "";
    let street = "";
    let postalCode = "";
    let city = "";
    let country = "";
    for (const component of address_components) {
      const componentType = component.types[0];
      // eslint-disable-next-line default-case
      switch (componentType) {
        case "street_number": {
          streetNumber = component.long_name;
          break;
        }

        case "route": {
          street = component.short_name;
          break;
        }

        case "postal_code": {
          postalCode = `${component.long_name}${postalCode}`;
          break;
        }

        case "postal_code_suffix": {
          postalCode = `${postalCode}${component.long_name}`;
          break;
        }

        case "locality":
          city = component.long_name;
          break;

        case "country":
          country = component.long_name;
          break;
      }
    }

    street = street ? street + " " + streetNumber : "";

    return {
      street,
      postalCode: postalCode.replace(/\s/g, ""),
      city,
      country,
    };
  };

  React.useLayoutEffect(() => {
    console.log("window.google", window.google);
    if (typeof window.google !== "undefined") {
      const placeAutoComplete = new window.google.maps.places.Autocomplete(
        placeRef.current,
        _googlePlaceOption
      );
      console.log("sd", placeAutoComplete);
      const placeListener = placeAutoComplete.addListener(
        "place_changed",
        () => {
          try {
            const { street, postalCode, city, country } =
              _getPlace(placeAutoComplete);
            console.log("s", placeAutoComplete);
            console.log("places", street, postalCode, city, country);
            setOriginFormData({ ...originFormData, postalCode: postalCode });
            setAddress(street.replace(/\s/g, "") + ", " + city);
            setPostalCode(postalCode.replace(/\s/g, ""));
            setCountry(country);
            console.log(originFormData);
          } catch (e) {}
        }
      );

      return () => {
        window.google.maps.event.removeListener(placeListener);
      };
    }
  }, []);

  return (
    <div className="origin-tab">
      <div className="origin-tab-header">Where are you shipping from?</div>
      <div className="origin-tab-container">
        <div className="origin-tab-container-item">
          <div className="origin-tab-container-item-text">Address</div>
          <div className="origin-address-wrapper">
            <div className="origin-tab-container-item-field overflow-tab">
              <input
                ref={placeRef}
                value={address}
                onChange={(e) => {
                  setShowError(false);
                  setAddress(e.target.value);
                }}
                autoComplete="off"
                placeholder="Street Name"
                type="text"
              />
            </div>
            <div
              style={{ marginLeft: "10px" }}
              className="origin-tab-container-item-field postalCode-tab"
            >
              <input
                value={postalCode}
                style={{ marginLeft: "10px" }}
                onChange={(e) => {
                  setShowError(false);
                  setPostalCode(e.target.value);
                }}
                name="firstName"
                autoComplete="off"
                placeholder="Postal Code"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="origin-tab-container-item">
          <div className="origin-tab-container-item-text">Country</div>
          <div className="origin-tab-container-item-field">
            <input
              value={country}
              onChange={(e) => {
                setShowError(false);
                setCountry(e.target.value);
              }}
              name="firstName"
              autoComplete="off"
              placeholder="195 countries"
              type="text"
            />
          </div>
        </div>

        <div className="origin-tab-container-item origin-tab-select-container">
          <div className="origin-tab-container-item-text">Location Type</div>
          <div className="origin-tab-container-item-field origin-tab-select">
            <div className="origin-tab-options">
              <div
                onClick={() => {
                  setSelectedLocationType(0);
                }}
                className={
                  selectedLocationType === 0
                    ? "origin-tab-option origin-tab-selected"
                    : "origin-tab-option"
                }
              >
                Port/Airport
              </div>
              <div
                onClick={() => {
                  setSelectedLocationType(1);
                }}
                className={
                  selectedLocationType === 1
                    ? "origin-tab-option origin-tab-selected"
                    : "origin-tab-option"
                }
              >
                Factory/Warehouse
              </div>
              <div
                onClick={() => {
                  setSelectedLocationType(2);
                }}
                className={
                  selectedLocationType === 2
                    ? "origin-tab-option origin-tab-selected"
                    : "origin-tab-option"
                }
              >
                Business Address
              </div>
              <div
                onClick={() => {
                  setSelectedLocationType(3);
                }}
                className={
                  selectedLocationType === 3
                    ? "origin-tab-option origin-tab-selected"
                    : "origin-tab-option"
                }
              >
                Residential Address
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="origin-tab-footer">
        <div onClick={clickHandler} className="origin-tab-footer-button">
          Next
        </div>
      </div>
      {showError && (
        <div className="error-postal-code">Invalid Postal Code</div>
      )}
    </div>
  );
};

export default OriginTab;
