import { Box, Modal } from '@mui/material'
import React from 'react'
import { GridLoader } from 'react-spinners'
import '../../assests/styles/NewPassword.css'
import { style } from '../../constants'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import { sendEmailForForgotPassword, setNewPassword } from '../../api'
import CheckSVG from '../../assests/svg/check'
import SuccessModal from '../../components/SuccessModal'
import ErrorModal from '../../components/ErrorModal'


const NewPassword = () => {
    const [stage, setStage] = React.useState(-1);
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState('')
    const [successModal, setSuccessModal ] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const token = window.location.pathname.split('/')[2];
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);
    const navigate = useNavigate();

    const updatePasswordHandler = () => {
        if(password !== '' && password === confirmPassword) {
            setLoading(true);
            setNewPassword(password).then((res)=>{
                console.log(res);
                setLoading(false);
                setStage(0);
                setSuccessModal(true);
    
            }).catch((err)=>{
                console.log(err?.response?.data?.detail);
                setStage(1);
                setShowError(true);
                setErrorMessage(err?.response?.data?.detail);
                setLoading(false);
            })
        }
        else {
            setStage(1);
            setShowError(true);
            setErrorMessage("Passwords do not match");
        }
       
    }

    return (
        <>
            <Navbar />
            <div className="new-password-page"></div>
            <Modal
                className="login-modal"
                open={loading}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <GridLoader cssOverride={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate( -50%, -50%)' }} loading={loading} />
            </Modal>
            {stage === -1 && 
            <Modal
                className="new-password-modal"
                open={true}
                onClose={()=>{navigate('/')}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="new-password-header">Enter a new password</div>
                    <div className="new-password-container">
                        <div className="new-password-container-items"><input onChange={(e)=>{setPassword(e.target.value)}} name="emailFornew-password" autoComplete="off" placeholder="Enter your new password:"  type="password" /></div>
                        <div className="new-password-container-items"><input onChange={(e)=>{setConfirmPassword(e.target.value)}}  name="emailFornew-password" autoComplete="off" placeholder="Confirm your new password:"  type="password" /></div>
                        <div onClick={updatePasswordHandler} className="new-password-container-button"><p>Reset Password</p></div>
                    </div>
                </Box>

            </Modal>}
            {stage === 0 && <SuccessModal open={successModal} setOpen={()=>{
                setSuccessModal(false);navigate('/')
            }} title="Password Updated" message="New Password has been reset" />}
             {stage === 1 && <ErrorModal open={showError} setOpen={()=>{
                setShowError(false);navigate('/')
            }} title="Password Update Failed" message={errorMessage} />}
        </>
    )
}

export default NewPassword