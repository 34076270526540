import React from "react";
import LocationIcon from "../assests/images/locationIcon.png";
import ShopIcon from "../assests/images/shop.png";
import TruckIcon from "../assests/images/truck.png";
import "../assests/styles/ViewItemsTab.css";
import { setFinalData } from "../redux/actions/actions";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
import { getLocationType, getTypeOfPackaging } from "../constants";

const ViewItemsTab = ({ setSelectedTab, totalValues }) => {
  const [isViewMode, setIsViewMode] = React.useState(false);
  const dispatch = useDispatch();
  const app = useSelector(({ auth }) => auth);
  const FREIGHT_TAB = 2;

  const anotherFreightHandler = () => {
    dispatch(
      setFinalData({
        totalValues,
      })
    );
    setSelectedTab(FREIGHT_TAB);
  };

  const nextHandler = () => {
    dispatch(
      setFinalData({
        totalValues,
      })
    );
    setIsViewMode(true);
  };

  const [count, setCount] = React.useState(0);
  console.log(
    "app?.final_data[count]?.totalValues",
    app?.final_data[count]?.totalValues
  );
  return isViewMode ? (
    <div className="view-item-container">
      <div className="view-item-container-header">
        <div className="view-wrapper">
          <div className="view-item-container-header-item location-item">
            <img src={LocationIcon} alt="location-icon" />
            Origin
          </div>
          <div className="origin-input-field">
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Address : {app?.final_data[count]?.totalValues?.origin?.address}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Country : {app?.final_data[count]?.totalValues?.origin?.country}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Location Type :{" "}
                {getLocationType(
                  app?.final_data[count]?.totalValues?.origin?.location_type
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="view-wrapper">
          <div className="view-item-container-header-item truck-item">
            <img src={TruckIcon} alt="location-icon" />
            Load
          </div>
          <div className="origin-input-field">
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Type of Packaging :{" "}
                {getTypeOfPackaging(
                  app?.final_data[count]?.totalValues?.load?.typeOfPackaging
                )}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                # of units :{" "}
                {app?.final_data[count]?.totalValues?.load?.numberOfUnits}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Total Weight (KG) :{" "}
                {app?.final_data[count]?.totalValues?.load?.totalWeight}
              </div>
            </div>
          </div>
        </div>
        <div className="view-wrapper">
          <div className="view-item-container-header-item shop-item">
            <img src={ShopIcon} alt="location-icon" />
            Destination
          </div>
          <div className="origin-input-field">
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Address :{" "}
                {app?.final_data[count]?.totalValues?.destination?.address}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Country :{" "}
                {app?.final_data[count]?.totalValues?.destination?.country}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Location Type :{" "}
                {getLocationType(
                  app?.final_data[count]?.totalValues?.destination
                    ?.location_type
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="view-item-footer">
        <div className=" pagination">
          <Stack spacing={2}>
            <Pagination
              onChange={(e, page) => {
                setCount(page - 1);
              }}
              count={app?.final_data?.length}
              color="primary"
            />
          </Stack>
        </div>
        {/* <div onClick={()=>{ setSelectedTab(4)}} className="view-item-footer-button"> Next</div> */}
        <div
          onClick={() => {
            setSelectedTab(4);
          }}
          className="next-button"
        >
          Next
        </div>
      </div>
    </div>
  ) : (
    <div className="view-item-container">
      <div className="view-item-container-header">
        <div className="view-wrapper">
          <div className="view-item-container-header-item location-item">
            <img src={LocationIcon} alt="location-icon" />
            Origin
          </div>
          <div className="origin-input-field">
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Address : {totalValues?.origin?.address}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Country : {totalValues?.origin?.country}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Location Type :{" "}
                {getLocationType(totalValues?.origin?.location_type)}
              </div>
            </div>
          </div>
        </div>
        <div className="view-wrapper">
          <div className="view-item-container-header-item truck-item">
            <img src={TruckIcon} alt="location-icon" />
            Load
          </div>
          <div className="origin-input-field">
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Type of Packaging :{" "}
                {getTypeOfPackaging(totalValues?.load?.typeOfPackaging)}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                # of units : {totalValues?.load?.numberOfUnits}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Total Weight (KG) : {totalValues?.load?.totalWeight}
              </div>
            </div>
          </div>
        </div>
        <div className="view-wrapper">
          <div className="view-item-container-header-item shop-item">
            <img src={ShopIcon} alt="location-icon" />
            Destination
          </div>
          <div className="origin-input-field">
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Address : {totalValues?.destination?.address}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Country : {totalValues?.destination?.country}
              </div>
            </div>
            <div className="view-tab-container-item-field">
              <div className="view-item-labels overflow-feature">
                Location Type :{" "}
                {getLocationType(totalValues?.destination?.location_type)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="view-item-footer">
        <div
          onClick={anotherFreightHandler}
          className="view-item-footer-button"
        >
          {" "}
          Add freight
        </div>
        <div onClick={nextHandler} className="view-item-footer-button">
          {" "}
          Next
        </div>
      </div>
    </div>
  );
};

export default ViewItemsTab;
