export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  export const gridLoaderCSS = 
    { position: 'absolute', top: '45%', left: '50%', transform: 'translate( -50%, -50%)' }
  

 export const reasonForEmail = "email_confirmation"
 export const registrationSuccessTitle="Registration Complete"
 export const registrationFailedTitle="Registration Failed"
 export const loginSuccessTitle="Login Complete"
 export const loginFailedTitle="Login Failed"
 export const registrationSuccessMessage="An email has been sent with the verification link!"
 export const loginSuccessMessage="Successfully logged in as "
 export const loginErrorMessage=""

 export const getTypeOfPackaging = (e) => {
  if(e === 0){
    return "Pallet"
  }
  if(e === 1){
    return "Box"
  }
  if(e === 2){
    return "Crate"
  }
  if(e === 3){
    return "Bundle"
  }
  if(e === 4){
    return "Drum"
  }
  if(e === 5){
    return "Roll"
  }
  if(e === 6){
    return "Bale"
  }
  if(e === 7){
    return "Euro Pallet"
  }
 }

 export const getLocationType = (e) => {
  if(e === 0){
    return "Port/Airport"
  }
  if(e === 1){
    return "Factory/Warehouse"
    
  }
  if(e === 2){
    return "Business Address"
  }
  if(e === 3){
    return "Residential Address"
  }
 }