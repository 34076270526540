import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Protected from "./components/Protected";
import About from "./pages/About";
import LandingPage from "./pages/LandingPage";
import Navbar from "./pages/Navbar";
import Profile from "./pages/Profile";
import { useSelector, useDispatch } from 'react-redux';
import Subscriptions from './pages/subscriptions/Subscriptions';
import ForgotPassword from './pages/authentication/ForgotPassword';
import NewPassword from './pages/authentication/NewPassword';
import ContactUs from './pages/ContactUs';
import { removeResult, setCurrentUser } from './redux/actions/actions';
function App() {
  const [isAuth, setIsAuth] = React.useState(false);
  const app = useSelector(({ auth }) => auth)
  const dispatch = useDispatch()

  const isNewUser = app?.currentUser?.firstName === undefined
  const authentication = (e) => {
    setIsAuth(e)
  }

  React.useEffect(()=>{
    if (window.performance) {
      if (performance.navigation.type == 1) {
        console.log( "This page is reloaded" );
        dispatch(removeResult())
      } else {
        if(app?.currentUser?.firstName?.length > 1 ){
              dispatch(setCurrentUser({}))
            }
      }
    }
  },[])

  return (
    <BrowserRouter>
      <div>

        <Routes>
          <Route exact path='/subscriptions'
            element={<Subscriptions authentication={authentication} />}
          />
          <Route exact path='/' element={
            <><Navbar authentication={authentication} />
              <LandingPage isAuth={isAuth} /></>
          } />
          <Route exact path='/forgot_password'
            element={ <ForgotPassword />}
          />
            <Route exact path='/contact'
            element={ <ContactUs authentication={authentication} isAuth={isAuth} />}
          />
            <Route exact path='/new_password'
            element={ <NewPassword />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
export default App;