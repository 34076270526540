import { Box, Modal } from '@mui/material'
import React from 'react'
import { style } from '../constants'
import '../assests/styles/SuccessModal.css'
import CheckSVG from '../assests/svg/check'
const SuccessModal = ({ open, setOpen, title, message,hideLogo }) => {
    return  (!hideLogo?
        <Modal
            className="success-modal"
            open={open}
            aria-labelledby="modal-modal-title"
            onClose={() => { setOpen(false) }}
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="success-modal">
                    <div className="success-svg">
                        <CheckSVG />
                    </div>
                    <div className="success-header">
                        {title}
                    </div>
                    <div className="success-body">
                        {message}
                    </div>
                </div>
            </Box>
        </Modal>: <Modal
            className="success-modal no-logo"
            open={open}
            aria-labelledby="modal-modal-title"
            onClose={() => { setOpen(false) }}
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="message-container" >
                <div className="success-header">
                        {title}
                    </div>
                    <div className="success-body">
                        {message}
                    </div>
                </div>

            </Box>
        </Modal>
    )
}

export default SuccessModal