import React from 'react'
import { useSelector } from 'react-redux';
import '../assests/styles/LandingPage.css'
import ShipmentModal from '../components/ShipmentModal';
const LandingPage = ({isAuth}) => {
    const [open, setOpen] = React.useState(false);
    const startHandler = () => {
      if(!isNewUser){
        setOpen(true);
      }  
    }
    const app = useSelector(({ auth }) => auth)
    const isNewUser = app?.currentUser?.firstName === undefined
    if (window.performance) {
        if (performance.navigation.type == 1) {
          console.log( "This page is reloaded" );
        } else {
          console.log( "This page is not reloaded");
        }
      }
    return (
        <div className="landing-page">
          {open &&   <ShipmentModal open={open} setOpen={setOpen} />}
            {!isAuth && <div className="landing-page-header">
                <div className="landing-page-header-container">
                    <div className="landing-page-header-item item-1">
                        <p>KAIZEN</p>
                    </div>
                    <div className="landing-page-header-item item-2">
                        <p>Road Freight Pricing</p>
                    </div>
                    <div onClick={startHandler} className="landing-page-header-item item-3">
                        <p>START</p>
                    </div>
                </div>

            </div>}
        </div>
    )
}

export default LandingPage