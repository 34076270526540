import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assests/styles/Subscriptions.css';
import SuccessModal from '../../components/SuccessModal';
import Navbar from '../Navbar';

const Subscriptions = ({ authentication }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  return (
    <>
    <SuccessModal hideLogo={true} open={open} setOpen={setOpen} message="This feature is yet to be released" title="Coming Soon" />
      <Navbar authentication={authentication} />
      <div className="subscription-page">
        <div className="subscription-page-header">
          <div className="subscription-page-header-container">
            <div className="subscription-page-header-container-top background-1">
              Free
            </div>
            <div className="subscription-page-header-container-middle">
              0 €/month
            </div>
            <div className="subscription-page-header-container-bottom">
              Up to 25 free transactions on first registration
            </div>
            <div className="choose-plan choose-plan-free">
              Current Plan
            </div>
          </div>
          <div className="subscription-page-header-container">
            <div className="subscription-page-header-container-top background-2">
              Gold
            </div>
            <div className="subscription-page-header-container-middle">
              14.99 €/month
            </div>
            <div className="subscription-page-header-container-bottom">
              Up to 100 transactions
            </div>
            <div  onClick={()=>{
              setOpen(true);
            }} className="choose-plan choose-plan-gold">
              Choose Plan
            </div>
          </div>
          <div className="subscription-page-header-container">
            <div className="subscription-page-header-container-top background-3">
              Diamond
            </div>
            <div className="subscription-page-header-container-middle">
              24.99 €/month
            </div>
            <div className="subscription-page-header-container-bottom">
              Up to 500 transactions
            </div>
            <div  onClick={()=>{
              setOpen(true);
            }} className="choose-plan choose-plan-diamond">
              Choose Plan
            </div>
          </div>
          <div className="subscription-page-header-container">
            <div className="subscription-page-header-container-top background-4">Enterprise</div>
            <div className="subscription-page-header-container-middle"></div>
            <div className="subscription-page-header-container-bottom unlimited">
              Unlimited
            </div>
            <div onClick={()=>{navigate('/contact')}} className="contact-us">Contact Us</div>
          </div>
        </div>
      </div>



    </>

  )
}

export default Subscriptions