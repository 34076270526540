import axios from 'axios';
import {  THE_ZIP_CODES_URL } from '../env';

export const currentPlan = ( token ) => {
    return new Promise((resolve, reject) => {
        axios.get(
            "https://api.kpestack.com/user/currentplan",
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        ).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}


export const login=(email,password)=>{
    return new Promise((resolve,reject)=>{
        let axiosConfig = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
            }
          };
          axios.post(`https://api.kpestack.com/auth/login`, {
            email,
            password
          }, axiosConfig).then((res) => {
            localStorage.setItem('access_token', res.data.access_token);
            resolve(res)
          }).catch((err) => {
            reject(err)
      
          })
    })
}


export const signup=(email,password, firstName, lastName)=>{
    return new Promise((resolve,reject)=>{
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };
        axios.post(`https://api.kpestack.com/auth/register`, {
            email,
            password,
            first_name : firstName, 
            last_name: lastName
        }, axiosConfig).then((res) => {
            resolve(res)
            // history.push('/login')
        }).catch((err) => {
            reject(err.response.data.detail);
        })
    })
}

export const checkout = ( token, product) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "https://price-test-engine.herokuapp.com/checkout",
            { token, product }
        ).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}

export const calculateDistance = (pickUpPincode, dropOffPincode) => {
    return new Promise((resolve, reject) => {
        axios.get(`${THE_ZIP_CODES_URL}/api/v1/distance?fromZipCode=${pickUpPincode}&toZipCode=${dropOffPincode}&unit=MI&countryCode=DE&apiKey=cbbdd1f35b3d7bd690cee98527aee610`).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err);
        })
    })
}

export const predictPrice = (data, token) => {
    return new Promise((resolve, reject) => {
        axios.post(`https://api.kpestack.com/pengine/prediction/`,
            data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
        }).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err);
        })
    })
}

export const predictPriceUsingId = (id, token) => {
    return new Promise((resolve, reject) => {
        axios.get(`https://api.kpestack.com/pengine/${id}`,
             {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
        }).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err);
        })
    })
}


export const getLocation = (zipcode) => {
    return new Promise((resolve, reject) => {
        axios.get(`${THE_ZIP_CODES_URL}/api/v1/search?zipCode=${zipcode}&countryCode=&apiKey=ff577b079e3556a60846e2677128e2f1`).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
}

export const verify = (email, reason) => {
    return new Promise((resolve, reject) => {
        axios.post('https://api.kpestack.com/auth/verify/', {
            email, reason
        }).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
}

export const subscription = (token) => {
    return new Promise((resolve, reject) => {
        axios.post('https://api.kpestack.com/subscription/user', {
            "bundle_name": "free",
          },
            {headers: {
                'Authorization': `Bearer ${token}`
            }}).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
}

export const sendEmailForForgotPassword = (email) => {
    return new Promise((resolve, reject) => {
        axios.post('https://api.kpestack.com/auth/verify/', {
            email: email,
            reason: "forget_password"
          },
           ).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
}

export const setNewPassword= (password, token) => {
    return new Promise((resolve, reject) => {
        axios.put('https://api.kpestack.com/user/updatepwd', {
            new_password: password
          },
          {
            headers: {
                'Authorization': `Bearer ${token}`
            }}
           ).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
}

export const resetPassword= (new_password, token) => {
    return new Promise((resolve, reject) => {
        axios.put('https://api.kpestack.com/user/updatepwd', {
            new_password
          },
          {
            headers: {
                'Authorization': `Bearer ${token}`
            }}
           ).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
}


export const priceAcceptedApi= (id, token) => {
    return new Promise((resolve, reject) => {
        axios.put(`https://api.kpestack.com/pengine/${id}`,
        {},{
            headers: {
                'Authorization': `Bearer ${token}`
            }}
           ).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
}




export const sendEmail= (contactUsForm) => {
    return new Promise((resolve, reject) => {
        axios.post('https://api.kpestack.com/email/sales', contactUsForm,
           ).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
}

