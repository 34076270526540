import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';
import { GridLoader } from 'react-spinners';
import { signup, verify } from '../../api';
import '../../assests/styles/Registration.css';
import ErrorModal from '../../components/ErrorModal';
import SuccessModal from '../../components/SuccessModal';
import { gridLoaderCSS, reasonForEmail, registrationFailedTitle, registrationSuccessMessage, registrationSuccessTitle, style } from '../../constants/index';
import { EmailService } from '../../services/emailValidation';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const Registration = ({ open, handleClose, handleOpen }) => {
    const [loading, setLoading] = React.useState(false);
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('')
    const [signUpForm, setSignUpForm] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    })

    const verificationComplete = () => {
        setLoading(false);
        setShowSuccessModal(true)
    }

    const isRegistrationValid = () => {
        const isFormEmpty = !Object.values(signUpForm).some(x => (x !== null && x !== ''))
        const isEmailValid = EmailService.isEmailValid(signUpForm.email)
        if(isFormEmpty){
            return {
                message: 'No fields entered',
                status: false
            }
        }
        if(signUpForm.email === ''){
            return {
                message: 'Email cannot be an empty string',
                status: false
            }
        }
        if(!isEmailValid){
            return {
                message: 'Email is not valid',
                status: false
            }
        }
        if(signUpForm.firstName === '' && signUpForm.lastName === ''){
            return {
                message: 'Name cannot be an empty string',
                status: false
            }
        }
        if(signUpForm.password === ''){
            return {
                message: 'Password must not be an empty string',
                status: false
            }
        }
        if(signUpForm.password !== signUpForm.confirmPassword){
            return {
                message: 'Passwords do not match',
                status: false
            }
        }
        return {
            message: '',
            status: true
        }
    }

    const submitHandler = () => {
        const emailValidation = isRegistrationValid();
        if(emailValidation.status && signUpForm.password !== ''){
            const isValid = EmailService.isEmailValid(signUpForm.email)
            if (isValid) {
                handleClose()
                setLoading(true);
                if (signUpForm.password === signUpForm.confirmPassword) {
                    signup(signUpForm.email, signUpForm.password, signUpForm.firstName, signUpForm.lastName).then((response) => {
                        console.log('response fom /register', response)
                        verify(signUpForm.email, reasonForEmail).then((res) => {
                            console.log('response fom /verify', res)
                            verificationComplete();
                            setSignUpForm({})
                        }).catch((error) => {
                            console.log('/verify failed', error);
                            setLoading(false);
                            setShowErrorModal(true);
                            setErrorMessage(error)
                            setSignUpForm({})
                        })
                    }).catch((err) => {
                        console.log('/register failed', err);
                        setLoading(false);
                        setShowErrorModal(true);
                        setErrorMessage(err)
                        setSignUpForm({})
                    })
                }

            } else {
                setShowErrorModal(true);
                handleClose()
                setErrorMessage('Email is in wrong format')
                setSignUpForm({})
            }
        }
        else {
            handleClose()
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(emailValidation.message)
            setSignUpForm({})
        }
    }

    return (
        <div className="registration">
            <SuccessModal open={showSuccessModal} setOpen={setShowSuccessModal} title={registrationSuccessTitle} message={registrationSuccessMessage} />
            <ErrorModal open={showErrorModal} setOpen={setShowErrorModal} title={registrationFailedTitle} message={errorMessage} />
            <Modal
                className="login-modal"
                open={loading}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <GridLoader cssOverride={gridLoaderCSS} loading={loading} />
            </Modal>
            <Modal
                className="registration-modal"
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <div className="close-icon-wrapper">
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    </div>
                    <div className="registration-container">
                        <div className="registration-container-items"><input name="firstName" autoComplete="off" onChange={(e) => { setSignUpForm({ ...signUpForm, firstName: e.target.value }) }} placeholder="First Name:" type="text" /></div>
                        <div className="registration-container-items"><input name="lastName" autoComplete="off" onChange={(e) => { setSignUpForm({ ...signUpForm, lastName: e.target.value }) }} placeholder="Last Name:" type="text" /></div>
                        <div className="registration-container-items"><input name="emailForRegistration" autoComplete="off" onChange={(e) => { setSignUpForm({ ...signUpForm, email: e.target.value }) }} placeholder="Enter your email:" type="text" /></div>
                        <div className="registration-container-items"><input name="passwordForRegistration" autoComplete="off" onChange={(e) => { setSignUpForm({ ...signUpForm, password: e.target.value }) }} placeholder="Enter your password:" type="password" /></div>
                        <div className="registration-container-items"><input name="confirmPasswordForRegistration" autoComplete="off" onChange={(e) => { setSignUpForm({ ...signUpForm, confirmPassword: e.target.value }) }} placeholder="Re-enter your password:" type="password" /></div>
                        <div onClick={submitHandler} className="registration-container-button"><p>Sign up</p></div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default Registration