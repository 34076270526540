import * as AuthActions from '../actions/actions'

const initialState = {
    pickupPincode: 0,
    dropoffPincode: 0,
    shipment_details: {},
    user: {},
    isAuthenticated: false,
    payment: [],
    distance: [],
    currentPlan: '',
    currentUser: {},
    loadingMeter: '',
    final_data:[],
    priced_data: [],
    priced_data_id: []
}

export default function authReducer(state = initialState, action) {
    console.log('action: ', action);
    switch (action.type) {
        case AuthActions.PICKUP:
            return {
                ...state,
                pickupPincode: action.payload,
            }
        case AuthActions.DROPOFF:
            return {
                ...state,
                dropoffPincode: action.payload,
            }
        case AuthActions.SHIPMENT_DETAILS:
            return {
                ...state,
                shipment_details: action.payload,
            }
        case AuthActions.LOG_IN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true
            }
        case AuthActions.SIGN_IN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true
            }
        case AuthActions.PAYMENT_SUCCESS:
            return {
                ...state,
                payment: action.payload,
            }
        case AuthActions.DISTANCE_CALCULATE_SUCCESS:
            return {
                ...state,
                distance: action.payload
            }
        case AuthActions.CURRENT_PLAN:
            return {
                ...state,
                currentPlan: action.payload
            }
        case AuthActions.FINAL_DATA:
            return {
                ...state,
                final_data: [...state.final_data, action.payload]
            }
        case AuthActions.REMOVE_FINAL_DATA:
                return {
                    ...state,
                    final_data: action.payload,
                }   
        case AuthActions.PRICED_DATA:
            return {
                ...state,
                priced_data: [...state.priced_data, action.payload]
            }
        case AuthActions.CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload
            }
        case AuthActions.LOADING_METER:
            return {
                ...state,
                loadingMeter: action.payload
            }
        case AuthActions.PRICED_DATA_FROM_ID:
            return {
                ...state,
                priced_data_id: [...state.priced_data_id, action.payload]
            }
        case AuthActions.REMOVE_PRICED_DATA_FROM_ID:
            return {
                ...state,
                priced_data_id: [],
                final_data: []
            }
        default:
            return state
    }
}