import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridLoader } from 'react-spinners';
import { currentPlan, login, resetPassword, subscription } from '../../api';
import { useNavigate } from 'react-router-dom';
import '../../assests/styles/ResetPassword.css'
import ErrorModal from '../../components/ErrorModal';
import SuccessModal from '../../components/SuccessModal';
import { loginFailedTitle, loginSuccessMessage, loginSuccessTitle, style } from '../../constants/index';
import { saveToken, setCurrentUser } from '../../redux/actions/actions';
import { EmailService } from '../../services/emailValidation';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ResetPassword = ({ open, handleClose }) => {
    const app = useSelector(({ auth }) => auth)
    const [loading, setLoading] = React.useState(false);
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);
    const navigate = useNavigate();

    const [resetForm, setResetForm] = React.useState({
        oldPassword: '',
        newPassword: '',
    })
    const [errorMessage, setErrorMessage] = React.useState('')
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const resetHandler = () => {
        if(resetForm.newPassword !== "" && resetForm.newPassword.length > 0 && resetForm.newPassword === resetForm.oldPassword) {
            setLoading(true);
            resetPassword(resetForm.newPassword, app?.currentUser?.token).then(()=>{
                handleClose()
                setLoading(false);
                setShowSuccessModal(true)
                setTimeout(()=>{
                    setShowSuccessModal(false)
                },[2000])
            }).catch((err)=>{
                setShowErrorModal(true);
                setErrorMessage(err?.response?.data?.detail)
                setLoading(false);
                setResetForm({})
            })
        }
        else {
            handleClose()
            setShowErrorModal(true);
            setErrorMessage("Password not valid")
            setLoading(false);
            setResetForm({})
        }
    }

    const forgotPasswordHandler = () => {
        handleClose()
        navigate('/forgot_password')
    }
    return (
        <div className="login">
            <SuccessModal open={showSuccessModal} setOpen={setShowSuccessModal} title={"Reset Password Success"} message={"Password successfully updated"} />
            <ErrorModal open={showErrorModal} setOpen={setShowErrorModal} title={"Password reset failed"} message={errorMessage} />
            {/* loader {} */}
            <Modal
                className="login-modal"
                open={loading}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <GridLoader cssOverride={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate( -50%, -50%)' }} loading={loading} />
            </Modal>
            <Modal
                className="login-modal"
                open={open}
              
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                <div className="close-icon-wrapper">
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    </div>

                    <div className="reset-header">Reset Password</div>
                    <div className="reset-container">
                        <div className="reset-container-items"><input name="emailForreset" autoComplete="off" onChange={(e) => { setResetForm({ ...resetForm, oldPassword: e.target.value }) }} placeholder="Enter your new password:" type="password" /></div>
                        <div className="reset-container-items"><input name="passwordForreset" autoComplete="new-password" onChange={(e) => { setResetForm({ ...resetForm, newPassword: e.target.value }) }} placeholder="Confirm your new password:" type="password" /></div>
                        <div onClick={resetHandler} className="reset-container-button"><p>Reset Password</p></div>
                    </div>

                </Box>

            </Modal>

        </div>
    )
}

export default ResetPassword