import { Box, Modal } from '@mui/material'
import React from 'react'
import { GridLoader } from 'react-spinners'
import '../../assests/styles/ForgotPassword.css'
import { style } from '../../constants'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import { sendEmailForForgotPassword } from '../../api'
import CheckSVG from '../../assests/svg/check'
import SuccessModal from '../../components/SuccessModal'
import ErrorModal from '../../components/ErrorModal'


const ForgotPassword = () => {
    const [email, setEmail] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [stage, setStage] = React.useState(-1);
    const [successModal, setSuccessModal] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);
    const navigate = useNavigate();
    const sendMailHandler = () => {
        setLoading(true);
        sendEmailForForgotPassword(email).then((res)=>{
            setLoading(false);
            setStage(0);
            setSuccessModal(true);
        }).catch((err)=>{
            console.log(err?.response?.data?.detail);
            setStage(1);
            setShowError(true);
            setErrorMessage(err?.response?.data?.detail);
            setLoading(false);
        })
    }
    return (
        <>
            <Navbar />
            <div className="forgot-password-page"></div>
            <Modal
                className="login-modal"
                open={loading}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <GridLoader cssOverride={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate( -50%, -50%)' }} loading={loading} />
            </Modal>
            {stage === -1 && <Modal
                className="forgot-password-modal"
                open={true}
                onClose={()=>{navigate('/')}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="forgot-password-header">Forgot Password</div>
                    <div className="forgot-password-container">
                        <div className="forgot-password-container-items"><input onChange={(e)=>{setEmail(e.target.value)}} name="emailForforgot-password" autoComplete="off" placeholder="Enter your email:"  type="text" /></div>
                        <div onClick={sendMailHandler} className="forgot-password-container-button"><p>Send Email</p></div>
                    </div>
                </Box>

            </Modal>}
            {stage === 0 && <SuccessModal open={successModal} setOpen={()=>{
                setSuccessModal(false);navigate('/')
            }} title="Email Sent" message="An email with a confirmation link has been sent" />}
             {stage === 1 && <ErrorModal open={showError} setOpen={()=>{
                setShowError(false);navigate('/')
            }} title="Email Failed" message={errorMessage} />}
        </>
    )
}

export default ForgotPassword