import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import Logo from '../assests/images/logo.png';
import '../assests/styles/Navbar.css';
import { useSelector, useDispatch } from 'react-redux';
import Login from './authentication/Login';
import Registration from './authentication/Registration';
import { removeFinalData, setCurrentUser, setFinalData } from '../redux/actions/actions';
import { useNavigate } from 'react-router-dom';
import ResetPassword from './authentication/ResetPassword';
const Navbar = ({ authentication }) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const [open2, setOpen2] = React.useState(false);
    const [resetPasswordModal, setResetPasswordModal] = React.useState(false);
    const dispatch = useDispatch()
    const app = useSelector(({ auth }) => auth)
    const isNewUser = app?.currentUser?.firstName === undefined
    const handleOpen = () => { setOpen(true); authentication(true) }
    const handleResetPassword = () => { 
        setResetPasswordModal(false); 
        navigate('/');  
}
    const handleOpen2 = () => { setOpen2(true); authentication(true) }
    const handleClose = () => { setOpen(false); authentication(false) }
    const handleClose2 = () => { setOpen2(false); authentication(false) }
    const registrationHandler = () => {
        if(isNewUser){
            handleOpen()
        }
    }
    const loginHandler = () => {
        if(isNewUser){
            handleOpen2()
        } else {
            dispatch(setCurrentUser({}))
            dispatch(removeFinalData());
            navigate('/')
            
            
        }
    }
    return (
        <>
            <Registration open={open} handleOpen={handleOpen} handleClose={handleClose} />
            <ResetPassword open={resetPasswordModal} handleClose={handleResetPassword}/>
            <Login open={open2} handleOpen={handleOpen2} handleClose={handleClose2} />
            <div className="navbar">
                <div  onClick={()=>{navigate('/')}} className="navbar-left">
                    <img src={Logo} alt="logo" />
                    <div className="beta-tag">BETA</div>
                </div>
                <div className="navbar-right">
                    <div className="navbar-right-group-one">
                        <div className="wrap-nav">
                            <p>About</p>
                        </div>
                        <div onClick={()=>{navigate('/contact')}} className="wrap-nav">
                            <p>Contact Us</p>
                        </div>
                        <div onClick={()=>{if(!isNewUser){navigate('/subscriptions')}}} className="wrap-nav pricing-border">
                            <p>Pricing</p>
                            <ExpandMoreIcon style={{ marginLeft: '5px', color: 'black' }} />
                        </div>
                    </div>
                    <div className={!isNewUser? "navbar-right-group-two reset": "navbar-right-group-two"}>
                        <div onClick={registrationHandler} className="wrap-nav border">
                            <p>{isNewUser?<>Register</>:app?.currentUser?.firstName}</p>
                            {isNewUser && <ExpandMoreIcon style={{ marginLeft: '5px', color: 'white' }} />}
                        </div>
                        {!isNewUser &&  <div onClick={()=>{setResetPasswordModal(true)}} className="wrap-nav sign-in-border">
                                <p>Reset</p>
                        </div>}
                        <div onClick={loginHandler}  className="wrap-nav sign-in-border">
                            {isNewUser?<p>Sign in</p>:<p>Sign out</p>}
                        </div>
                     
                    </div>
                </div>
            </div>
        </>
    );
};
export default Navbar;