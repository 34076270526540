import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Modal } from "@mui/material";
import React from "react";
import "../assests/styles/ShipmentModal.css";
import { style } from "../constants";
import DestinationTab from "./DestinationTab";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadTab from "./LoadTab";
import OriginTab from "./OriginTab";
import RecommendPrice from "./RecommendPrice";
import ViewItemsTab from "./ViewItemsTab";
import { useDispatch } from "react-redux";
import { removeResult } from "../redux/actions/actions";
const ShipmentModal = ({ open, setOpen }) => {
  const [selectedTab, setSelectedTab] = React.useState(-1);
  const [originValues, setOriginValues] = React.useState({});
  const [destinationValues, setDestinationValues] = React.useState({});
  const [loadValues, setLoadValues] = React.useState({});
  const dispatch = useDispatch();

  const [totalLoadValues, setTotalLoadValues] = React.useState({});
  const storeOriginFormValues = (e) => {
    setOriginValues(e);
  };
  const storeDestinationFormValues = (e) => {
    setDestinationValues(e);
  };
  const storeLoadFormValues = (e) => {
    setLoadValues(e);
    setTotalLoadValues({
      origin: originValues,
      destination: destinationValues,
      load: e,
      ldm: (e.numberOfUnits * e.dimensionW * e.dimensionL) / 24000,
      weight: e.totalWeight,
    });
  };

  const handleClose = () => {
    setOpen(false);
    // remove state
    dispatch(removeResult());
  };

  return (
    <>
      <Modal
        className="shipment-modal"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="close-icon-wrapper-start">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {(selectedTab === -1 ||
            selectedTab === 0 ||
            selectedTab === 1 ||
            selectedTab === 2) && (
            <div className="shipment-modal-container">
              <div className="shipment-modal-container-header">
                <div
                  className={
                    selectedTab === 0
                      ? "shipment-modal-container-header-item selected"
                      : "shipment-modal-container-header-item"
                  }
                >
                  Origin{" "}
                  <ExpandMoreIcon className="shipment-header-expand-icon" />
                </div>
                <div
                  className={
                    selectedTab === 1
                      ? "shipment-modal-container-header-item selected"
                      : "shipment-modal-container-header-item"
                  }
                >
                  Destination{" "}
                  <ExpandMoreIcon className="shipment-header-expand-icon" />
                </div>
                <div
                  className={
                    selectedTab === 2
                      ? "shipment-modal-container-header-item selected"
                      : "shipment-modal-container-header-item"
                  }
                >
                  Load{" "}
                  <ExpandMoreIcon className="shipment-header-expand-icon" />
                </div>
              </div>
              {selectedTab === -1 && (
                <div className="shipment-modal-container-content">
                  <div
                    onClick={() => {
                      setSelectedTab(0);
                    }}
                    className="shipment-modal-container-content-start"
                  >
                    START
                  </div>
                </div>
              )}
              {selectedTab === 0 && (
                <div>
                  <OriginTab
                    storeOriginFormValues={storeOriginFormValues}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
              )}
              {selectedTab === 1 && (
                <div>
                  <DestinationTab
                    storeDestinationFormValues={storeDestinationFormValues}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
              )}
              {selectedTab === 2 && (
                <div>
                  <LoadTab
                    storeLoadFormValues={storeLoadFormValues}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
              )}
            </div>
          )}
          {selectedTab === 3 && (
            <ViewItemsTab
              totalValues={totalLoadValues}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 4 && <RecommendPrice handleClose={handleClose} />}
        </Box>
      </Modal>
    </>
  );
};

export default ShipmentModal;
