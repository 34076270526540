import * as Actions from '../../api/index'
export const PICKUP= 'PICKUP'
export const DROPOFF= 'DROPOFF'
export const SHIPMENT_DETAILS = 'SHIPMENT_DETAILS'
export const SIGN_UP_LOADING = 'SIGN_UP_LOADING'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'
export const LOG_IN_LOADING='LOG_IN_LOADING'
export const LOG_IN_SUCCESS='LOG_IN_SUCCESS'
export const SIGN_IN_SUCCESS='SIGN_IN_SUCCESS'
export const LOG_IN_ERROR='LOG_IN_ERROR'
export const PAYMENT_SUCCESS='PAYMENT_SUCCESS'
export const REMOVE_PRICED_DATA_FROM_ID = 'REMOVE_PRICED_DATA_FROM_ID'
export const DISTANCE_CALCULATE_SUCCESS = 'DISTANCE_CALCULATE_SUCCESS'
export const CURRENT_PLAN = 'CURRENT_PLAN'
export const CURRENT_USER = 'CURRENT_USER'
export const LOADING_METER = 'LOADING_METER'
export const FINAL_DATA = 'FINAL_DATA'
export const PRICED_DATA = 'PRICED_DATA'
export const REMOVE_FINAL_DATA = 'REMOVE_FINAL_DATA';
export const PRICED_DATA_FROM_ID = 'PRICED_DATA_FROM_ID'
export const pickupPincode= (pincode) => {
    return async function (dispatch) {
        dispatch({
            type: PICKUP,
            payload: pincode,
        })
    }
}

export const setCurrentUser = (user) => {
    return async function (dispatch) {
        dispatch({
            type:CURRENT_USER,
            payload: user
        })
    }
}

export const setFinalData = (data) => {
    return async function (dispatch) {
        dispatch({
            type:FINAL_DATA,
            payload: data
        })
    }
}

export const removeFinalData = () => {
    return async function (dispatch) {
        dispatch({
            type:REMOVE_FINAL_DATA,
            payload: []
        })
    }
}

export const removeResult = () => {
    return async function (dispatch) {
        dispatch({
            type:REMOVE_PRICED_DATA_FROM_ID,
            payload: []
        })
    }
}

export const setPricedData = (data) => {
    console.log('method action', data);
    return async function (dispatch) {
        dispatch({
            type:PRICED_DATA,
            payload: data
        })
    }
}

export const setPricedDataFromId = (data) => {
    console.log('method action', data);
    return async function (dispatch) {
        dispatch({
            type:PRICED_DATA_FROM_ID,
            payload: data
        })
    }
}

export const setCurrentPlan = (plan) => {
    return async function (dispatch) {
        dispatch({
            type:CURRENT_PLAN,
            payload: plan
        })
    }
}

export const dropoffPincode= (pincode) => {
    return async function (dispatch) {
        dispatch({
            type: DROPOFF,
            payload: pincode,
        })
    }
}

export const shipmentDetails= (shipment) => {
    return async function (dispatch) {
        dispatch({
            type: SHIPMENT_DETAILS,
            payload: shipment,
        })
    }
}

export const totalLoadingMeter= (ldm) => {
    return async function (dispatch) {
        dispatch({
            type: LOADING_METER,
            payload: ldm,
        })
    }
}

// authentication



export const loginUser=(email,password)=>{
  return async function(dispatch){
      try{
          const response=await Actions.login(email,password)
          if(response){
              dispatch({
                  type:LOG_IN_SUCCESS,
                  payload:response,
                  email:email
              })
              return response
          }else{
              return new Error()
          }
      }catch(error){
          return new Error()
      }
  }
  
}




export const signupUser=(email,password,firstName, lastName)=>{
  return async function(dispatch){
         const response= await Actions.signup(email,password, firstName, lastName).then((res)=>{
            dispatch({
                type:SIGN_IN_SUCCESS,
                payload:response,
                email:email
            })
            return res
         }).catch((err)=>{
             return new Error(err);
         })
  }
  
}


export const checkoutPayment=(token, product)=>{
  return async function(dispatch){
      try{
          const response=await Actions.checkout(token, product)
          if(response){
              dispatch({
                  type:PAYMENT_SUCCESS,
                  payload:response,
              })
              return response
          }else{
              return new Error()
          }
      }catch(error){
          return new Error()
      }
  }
  
}


export const calculatePincodesDistance=(pickUpPincode, dropOffPincode)=>{
    return async function(dispatch){
        try{
            const response=await Actions.calculateDistance(pickUpPincode, dropOffPincode)
            if(response){
                dispatch({
                    type:DISTANCE_CALCULATE_SUCCESS,
                    payload:response,
                })
                return response
            }else{
                return new Error()
            }
        }catch(error){
            return new Error()
        }
    }
    
  }