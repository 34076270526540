import React from 'react'
import '../assests/styles/LoadTab.css'
const LoadTab = ({ storeLoadFormValues, selectedTab, setSelectedTab }) => {
    const [packagingType, setSelectedPackagingType] = React.useState(-1);
    const [loadFormValues, setLoadFormValues] = React.useState({
        numberOfUnits: '',
        totalWeight: '',
        dimensionL: '',
        dimensionW: '',
        dimensionH: ''
    })
    const clickHandler = () => { 
        if(loadFormValues.numberOfUnits !== '' && loadFormValues.totalWeight !== '' && loadFormValues.dimensionH !== '' && loadFormValues.dimensionL !== '' && loadFormValues.dimensionW!== '' && packagingType!== -1){
            setSelectedTab(3);
            storeLoadFormValues({
                ...loadFormValues, typeOfPackaging: packagingType
            })
    
        }
       
     }

     const resetFormValues = () => {
        setLoadFormValues({...loadFormValues, dimensionH: '', dimensionW: '', dimensionL: ''})
     }
    return (
        <div className="load-tab">
            <div className="load-tab-container">
                <div className="load-tab-container-item load-tab-select-container">
                    <div className="load-tab-container-item-text">Type of packaging:</div>
                    <div className="load-tab-container-item-field load-tab-select">
                        <div className="load-tab-options">
                            <div onClick={() => { setSelectedPackagingType(0);resetFormValues() }} className={packagingType === 0 ? 'load-tab-option load-tab-selected' : 'load-tab-option'}>Pallet</div>
                            <div onClick={() => { setSelectedPackagingType(1);resetFormValues() }} className={packagingType === 1 ? 'load-tab-option load-tab-selected' : 'load-tab-option'}>Box</div>
                            <div onClick={() => { setSelectedPackagingType(2);resetFormValues() }} className={packagingType === 2 ? 'load-tab-option load-tab-selected' : 'load-tab-option'}>Crate</div>
                            <div onClick={() => { setSelectedPackagingType(3);resetFormValues() }} className={packagingType === 3 ? 'load-tab-option load-tab-selected' : 'load-tab-option'}>Bundle</div>
                            <div onClick={() => { setSelectedPackagingType(4);resetFormValues() }} className={packagingType === 4 ? 'load-tab-option load-tab-selected' : 'load-tab-option'}>Drum</div>
                            <div onClick={() => { setSelectedPackagingType(5);resetFormValues() }} className={packagingType === 5 ? 'load-tab-option load-tab-selected' : 'load-tab-option'}>Roll</div>
                            <div onClick={() => { setSelectedPackagingType(6);resetFormValues() }} className={packagingType === 6 ? 'load-tab-option load-tab-selected' : 'load-tab-option'}>Bale</div>
                            <div onClick={() => { setSelectedPackagingType(7);setLoadFormValues({...loadFormValues, dimensionH: 120, dimensionW:120, dimensionL: 80})  }} className={packagingType === 7 ? 'load-tab-option load-tab-selected' : 'load-tab-option'}>Euro Pallet</div>
                        </div>
                    </div>
                </div>
                <div className="load-tab-container-item wrapper">
                <div className="load-tab-container-item">
                    <div className="load-tab-container-item-text"># of units</div>
                    <div className="load-tab-container-item-field">
                        <input  onChange={(e)=>{setLoadFormValues({...loadFormValues, numberOfUnits: e.target.value})}} name="firstName" autoComplete="off" placeholder="Enter units" type="number" />
                    </div>
                </div>
                <div className="load-tab-container-item">
                    <div className="load-tab-container-item-text">Total Weight (KG)</div>
                    <div className="load-tab-container-item-field">
                        <input onChange={(e)=>{setLoadFormValues({...loadFormValues, totalWeight: e.target.value})}} name="firstName" autoComplete="off" placeholder="Enter total weight" type="number" />
                    </div>
                </div>
                </div>
                <div className="load-tab-container-item">
                    <div className="load-tab-container-item-text load-item">Dimensions (L×W×H per unit) (cm)</div>
                    <div className="input-fields-container">
                        <div className="load-tab-container-item-field load-field">
                            <input disabled={packagingType === 7} value={loadFormValues.dimensionL} onChange={(e)=>{setLoadFormValues({...loadFormValues, dimensionL: e.target.value})}}  name="firstName" autoComplete="off" placeholder="Length:" type="number" />
                        </div>
                        <div className="load-tab-container-item-field load-field">
                            <input disabled={packagingType === 7} value={loadFormValues.dimensionW} onChange={(e)=>{setLoadFormValues({...loadFormValues, dimensionW: e.target.value})}}  name="firstName" autoComplete="off" placeholder="Width:" type="number" />
                        </div>
                        <div className="load-tab-container-item-field load-field ">
                            <input disabled={packagingType === 7} value={loadFormValues.dimensionH} onChange={(e)=>{setLoadFormValues({...loadFormValues, dimensionH: e.target.value})}}  name="firstName" autoComplete="off" placeholder="Height:" type="number" />
                        </div>
                    </div>
                  
                </div>
            </div>
            <div className="load-tab-footer">
                <div onClick={clickHandler} className="load-tab-footer-button">Next</div>
            </div>

        </div>
    )
}

export default LoadTab